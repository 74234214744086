import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89')
];

export const server_loads = [0,2,5,7,11,12,13,14];

export const dictionary = {
		"/": [~20],
		"/admin": [~21,[2],[3]],
		"/admin/audit-log": [~25,[2],[3]],
		"/admin/labs": [~26,[2],[3]],
		"/admin/materials": [~27,[2],[3]],
		"/admin/permissions": [~28,[2],[3]],
		"/admin/[distChannel]/materials": [~22,[2],[3]],
		"/admin/[distChannel]/materials/[code]": [~23,[2],[3]],
		"/admin/[distChannel]/prices": [~24,[2],[3]],
		"/api/orders/[orderId]/[eye]/lens/profile": [~29],
		"/api/v1/docs": [~30],
		"/billing": [~31,[4]],
		"/billing/[distChannel]": [~32,[4]],
		"/billing/[distChannel]/orders": [~33,[4]],
		"/billing/[distChannel]/orders/[orderId]": [~34,[4]],
		"/clinic": [~35,[5]],
		"/clinic/admin": [~36,[5]],
		"/clinic/orders": [~37,[5]],
		"/clinic/orders/[orderId]": [~38,[5]],
		"/clinic/orders/[orderId]/reorder": [~40,[5]],
		"/clinic/orders/[orderId]/[eye]/lens/update": [~39,[5]],
		"/distributor": [~41,[6]],
		"/distributor/[distChannel]": [~42,[6,7]],
		"/distributor/[distChannel]/calculators": [~43,[6,7]],
		"/distributor/[distChannel]/calculators/practices": [~44,[6,7]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]": [~45,[6,7]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]/forge": [~46,[6,7,8]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]/scleral/step1": [~47,[6,7,9]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]/scleral/step2": [~48,[6,7,10]],
		"/distributor/[distChannel]/diagnostic": [~49,[6,7]],
		"/distributor/[distChannel]/labels": [~50,[6,7]],
		"/distributor/[distChannel]/orders": [~51,[6,7]],
		"/distributor/[distChannel]/orders/create": [~56,[6,7]],
		"/distributor/[distChannel]/orders/create/practices": [~57,[6,7]],
		"/distributor/[distChannel]/orders/create/practices/[practiceId]": [~58,[6,7]],
		"/distributor/[distChannel]/orders/[orderId]": [~52,[6,7]],
		"/distributor/[distChannel]/orders/[orderId]/new": [~55,[6,7]],
		"/distributor/[distChannel]/orders/[orderId]/[eye]/lens/spec/[rowNumber]": [~53,[6,7]],
		"/distributor/[distChannel]/orders/[orderId]/[eye]/lens/update": [~54,[6,7]],
		"/distributor/[distChannel]/practices": [~59,[6,7]],
		"/distributor/[distChannel]/practices/[practiceId]": [~60,[6,7]],
		"/kb": [~61,[11]],
		"/kb/search": [~63,[11]],
		"/kb/[slug]": [~62,[11,12]],
		"/lab": [~64],
		"/lab/[labCode]": [65,[13]],
		"/lab/[labCode]/batch/create": [~73,[13]],
		"/lab/[labCode]/batch/list": [~74,[13]],
		"/lab/[labCode]/checklist": [~75,[13]],
		"/lab/[labCode]/clear_submit_bar_codes": [~76,[13]],
		"/lab/[labCode]/errored-orders": [~77,[13]],
		"/lab/[labCode]/lens/material": [~78,[13]],
		"/lab/[labCode]/print/forms": [~79,[13]],
		"/lab/[labCode]/qc": [~80,[13]],
		"/lab/[labCode]/[orderId]/diagnosticSet/qc": [~70,[13]],
		"/lab/[labCode]/[orderId]/print/forms": [~71,[13]],
		"/lab/[labCode]/[orderId]/qc": [~72,[13]],
		"/lab/[labCode]/[orderId]/[eye]/lens/update": [~66,[13]],
		"/lab/[labCode]/[orderId]/[eye]/qc": [~67,[13]],
		"/lab/[labCode]/[orderId]/[eye]/schematic": [~68,[13,14]],
		"/lab/[labCode]/[orderId]/[eye]/schematic/[revision]": [~69,[13,14]],
		"/login": [81,[15]],
		"/orders/[orderId]/lens/[eye]/profile": [~82],
		"/tools": [~83,[16]],
		"/tools/spherical-power-effect": [~84,[16,17]],
		"/tools/toric-cross-cylinder": [~85,[16,18]],
		"/troubleshoot": [~86,[19]],
		"/troubleshoot/orders": [~87,[19]],
		"/v1/kb": [~88],
		"/v1/kb/[slug]": [~89]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';